/* UploadHistory.css */

.card {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: box-shadow 0.3s ease;
  }
  
  .card h3 {
    margin: 0 0 8px;
  }
  
  .card p {
    margin: 4px 0;
  }
  
  .card-failure::after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: red;
    border-radius: 0 0 4px 4px;
  }
  
  .card-completed::after {
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: green;
    border-radius: 0 0 4px 4px;
  }
  
  .card-progressing .progress-bar {
    background-color: yellow;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 4px 4px;
    transition: width 0.3s ease;
  }

  .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    color: #777; /* Adjust color to your preference */
    font-size: 16px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #333; /* Adjust hover color to your preference */
  }
  .text-red {
    color: red;
}

.text-green {
    color: green;
}

.text-yellow {
    color: yellow;
}
  